import styled from 'styled-components';

const Form = styled.form`
  margin-top: 40px;
  padding: 0 35px;
  display: flex;
  flex-direction: column;
  gap: 15px 0;
`;

const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 0 20px;
`;

const IconWrap = styled.div``;

const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  padding: 5px 10px;
  outline: 0;
  border: 1px solid rgba(255, 255, 255, .2);
  background: rgba(0, 0, 0, .2);
  color: #fbfbfe;
  font-family: Nunito, sans-serif;
  font-size: 14px;
`;

const Textarea = styled.textarea`
  box-sizing: border-box;
  width: 100%;
  padding: 5px 10px;
  outline: 0;
  border: 1px solid rgba(255, 255, 255, .2);
  background: rgba(0, 0, 0, .2);
  color: #fbfbfe;
  font-family: Nunito, sans-serif;
  font-size: 14px;
  resize: vertical;
`;

const Button = styled.button`
  box-sizing: border-box;
  width: 100%;
  padding: 5px;
  background-color: #3C7E89;
  color: #fbfbfe;
  outline: 0;
  border: 1px solid #60CADB;
  transition: 0.5s;
  
  :hover {
    border-color: #3C7E89;
    background-color: #60CADB;
    cursor: pointer;
  }
`;

const SuccessWrap = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0 12px;
`;

export { Form, Row, IconWrap, Input, Textarea, Button, SuccessWrap };
